import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="mdx-content">
      <h1 className="page__title">Polityka Prywatności</h1>
      <h2>§ 1<br /> POSTANOWIENIA OGÓLNE</h2>
      <ol>
        <li>Polityka prywatności określa zasady przetwarzania danych osobowych oraz przechowywania informacji lub uzyskiwania dostępu do informacji już przechowywanej w telekomunikacyjnym urządzeniu końcowym użytkownika serwisu <a href="https://sklep.devstyle.pl">https://sklep.devstyle.pl</a>.</li>
        <li>Jeśli w polityce prywatności wykorzystywany jest któryś z poniższych zwrotów, to należy mu nadać znaczenie następujące:</li>
        <ol>
          <li><strong>administrator</strong> – DEVSTYLE spółka z ograniczona odpowiedzialnością z siedzibą w Białymstoku (15-215) przy ul. Konopnickiej 14/8, NIP: 5423453088 (dawniej Maciej Aniserowicz prowadzący jednoosobową działalność gospodarczą, NIP 5422824401);</li>
          <li><strong>sklep</strong> – serwis internetowy umożliwiający nabywanie towarów i usług, dostępny w sieci web pod adresem <a href="https://sklep.devstyle.pl">https://sklep.devstyle.pl</a> i <a href="https://devstyle.salescrm.pl">https://devstyle.salescrm.pl</a>;</li>
          <li><strong>sprzedaż </strong>lub <strong>zakup </strong>– proces, podczas którego nabywasz w zamian za środki pieniężne towary lub usługi dostępne w sklepie;</li>
          <li><strong>usługa warunkowa</strong> - usługa oparta na dostępie warunkowym w rozumieniu ustawy o ochronie niektórych usług świadczonych drogą elektroniczną opartych lub polegających na dostępie warunkowym, będąca jednocześnie usługą w rozumieniu ustawy o świadczeniu usług drogą elektroniczną polegająca na możliwości skorzystania w ramach platformy edukacyjnej z dostępu za pomocą konta użytkownika do treści cyfrowych stanowiących własność usługodawcy;</li>
          <li><strong>użytkownik</strong> – to osoba fizyczna, która jest usługobiorcą usługi świadczonej drogą elektroniczną oraz świadczonych w jej ramach usług warunkowych.</li>
        </ol>
        <li>Sklep nie wymaga zakładania konta użytkownika do korzystania ze swojej pełnej funkcjonalności. Aby dokonać zakupu w sklepie niezbędne jest zaakceptowanie <a href="https://sklep.devstyle.pl/regulamin">regulaminu</a>.</li>
      </ol>
      <h2>§ 2<br /> PRZETWARZANIE DANYCH OSOBOWYCH</h2>
      <h3>Z KIM MOGĘ SIĘ KONTAKTOWAĆ W SPRAWACH DOTYCZĄCYCH OCHRONY DANYCH?</h3>
      <p>We wszystkich sprawach dotyczących ochrony danych osobowych możesz kontaktować się z inspektorem ochrony danych, którego wyznaczył administrator, pod adresem e-mail <a href="mailto:iod@devstyle.pl">iod@devstyle.pl</a>.</p>
      <h3>W JAKIM CELU SĄ PRZETWARZANE MOJE DANE?</h3>
      <p>Administrator przetwarzana Twoje dane osobowe w następujących celach, obok których podano podstawy prawne przetwarzania:</p>
      <ol>
        <li>zawarcie i świadczenie usługi świadczonej drogą elektroniczną - na podstawie art. 6 ust. 1 lit. b ogólnego rozporządzenia o ochronie danych (2016/679; dalej jako RODO);</li>
        <li>odprowadzanie należnego podatku od towarów i usług – na podstawie art. 6 ust. 1 lit. c) RODO w zw. z art. 19 ust. 5 pkt 4 i art. 108 ust. 1 ustawy o podatku od towarów i usług, ustawą o rachunkowości;</li>
        <li>odprowadzanie należnego podatku dochodowego – na podstawie art. 6 ust. 1 lit. c) RODO w zw. z art. 32 ordynacji podatkowej oraz przepisami ustawy o podatku dochodowym od osób fizycznych;</li>
        <li>realizacja obowiązku przechowywania faktur VAT wystawionych przez administratora – na podstawie art. 6 ust. 1 lit. c) RODO w zw. z art. 32 ustawy Ordynacja podatkowa;</li>
        <li>prawnie uzasadnione interesy administratora, na które składają się obsługa, dochodzenie i obrona przed wzajemnymi roszczeniami, również w przypadkach odstąpienia od umowy przez użytkownika, zapobieganie czynom zabronionym, realizacja marketingu usług własnych, prowadzenie wewnętrznych badań statystycznych dotyczących sposobu realizacji umowy, badanie poziomu zadowolenia z jakości świadczonej obsługi w związku z realizacją umowy oraz rozpatrywania reklamacji związanych z umową – na podstawie art. 6 ust. 1 lit. f rozporządzenia 2016/679;</li>
        <li>prowadzenia procesu negocjacyjnego pomiędzy reprezentowaną przez użytkownika firmą a administratorem, który może zakończyć się nawiązaniem współpracy gospodarczej polegającej na sprzedaży towarów lub usług przez administratora na rzecz reprezentowanej przez użytkownika firmy – art. 6 ust. 1 lit. f RODO.</li>
      </ol>
      <h3>KTO BĘDZIE ODBIORCĄ MOICH DANYCH?</h3>
      <p>Twoje dane osobowe są ujawniane podmiotom współpracującym z administratorem. Takie podmioty nazywa się odbiorcami danych. Ujawnienie danych przez administratora jest możliwe co do zasady w następujących przypadkach:</p>
      <ol>
        <li>gdy obowiązek taki wynika z przepisów prawa – dotyczy to ujawniania przez administratora Twoich danych;</li>
        <li>gdy administrator musi ujawnić dane, by móc zrealizować umowę z podmiotem trzecim - dotyczy to wszystkich działań, które mają związek z zapewnieniem poprawnego funkcjonowania platformy edukacyjnej; występuje w przypadku korzystania z rozwiązań informatycznych, których dostawcami są podmioty trzecie, niezależne od administratora i zawsze odbywa się na podstawie zawartej umowy powierzenia przetwarzania danych; dodatkowo może polegać także na ujawnieniu Twoich danych</li>
        <li>gdy administrator decyduje się na ujawnienie danych osobowych z powodu własnego, prawnie uzasadnionego interesu</li>
        <li>gdy wynika to z wyrażonej przez Ciebie zgody na przetwarzanie danych.</li>
      </ol>
      <p>Wszystkie powyższe przypadki są praktykami w pełni zgodnymi z RODO.</p>
      <p>Podmiotami, którym administrator ujawni Twoje dane osobowe będą:</p>
      <ol>
        <li>ConvertKit LLC (Kalifornia, Stany Zjednoczone Ameryki);</li>
        <li>dHosting sp. z o.o. (KRS 0000336780) ;</li>
        <li>Facebook Inc (Kalifornia, Stany Zjednoczone Ameryki);</li>
        <li>Google Ltd (Kalifornia, Stany Zjednoczone Ameryki);</li>
        <li>IMKER Bartnik Krzysztof (NIP 9222625515);</li>
        <li>PayPro S.A. (KRS 0000347935);</li>
        <li>Poczta Polska S.A. (KRS 0000334972);</li>
        <li>Krajowy Integrator Płatności (KRS 0000412357);</li>
        <li>Web INnovative Software sp. z o.o. (KRS 0000342082);</li>
        <li>Webio Grzegorz Wołoszyn (NIP 5992688099).</li>
      </ol>
      <h3>CZY MOJE DANE TRAFIAJĄ POZA EUROPEJSKI OBSZAR GOSPODARCZY?</h3>
      <p>W pewnych szczególnych sytuacjach, administrator może przekazywać Twoje dane osobowe do innych państw, niż należące do Europejskiego Obszaru Gospodarczego – tzw. państw trzecich. Obejmuje to zawsze przypadki, które są niezbędne np. w celu wykonania umowy czy zapewnia prawidłowej pracy poszczególnych funkcjonalności sklepu. W takich sytuacjach administrator zawsze zawiera umowę powierzenia przetwarzania, która zapewnia odpowiedni poziom bezpieczeństwa Twoich danych. Wszystkie podmiot przetwarzające, którym administrator powierza Twoje dane osobowe zostały certyfikowane w programie Tarcza Prywatności (ang. Privacy Shield), zatwierdzonym decyzją Komisji Europejskiej.
      </p>
      <h3>JAK DŁUGO BĘDĄ PRZETWARZANE MOJE DANE?</h3>
      <p>Administrator przetwarza Twoje dane przez różny czas. Zależy to od celu przetwarzania danych oraz ich rodzaju. Możemy wskazać następujące okresy:</p>
      <ol>
        <li>przez 6 lat, licząc od dnia, w którym skontaktowałeś się z administratorem;
        </li>
        <li>przez 6 lat licząc od dnia, w którym odwiedziłeś stronę zarządzaną przez administratora;</li>
        <li>przez 5 lat od końca roku kalendarzowego, w którym upłynął termin płatności podatku wymagany przez przepisy regulujące zobowiązania podatkowe - na podstawie art. 32 i 70 § 1 ustawy Ordynacja podatkowa.</li>
      </ol>
      <h3>JAKIE PRAWA PRZYSŁUGUJĄ MI W ZWIĄZKU Z PRZETWARZANIEM MOICH DANYCH OSOBOWYCH?</h3>
      <p>Przysługuje Tobie prawo do żądania od administratora sprostowania, usunięcia, ograniczenia lub wniesienia sprzeciwu dotyczącego przetwarzania Twoich danych osobowych. Prawa te przysługują Tobie tylko w sytuacjach, w których wniesienie takiego żądania jest faktycznie uzasadnione.</p>
      <h3>CZY MUSZĘ PODAWAĆ MOJE DANE OSOBOWE?</h3>
      <p>Jeśli tylko przeglądasz sklep to nie musisz podawać swoich danych osobowych. Jeśli nie chcesz aby Twoje dane osobowe były poddawane działaniom analitycznym i statystycznym możesz skorzystać ze specjalnych wtyczek do przeglądarki, które uniemożliwią zbieranie danych (np. poprzez podawanie fikcyjnych informacji o Twojej przeglądarce czy Twoim sposobie zachowania). Jeśli decydujesz się zakupić towary lub usługi od administratora to bez podania Twoich danych osobowych nie uda się złożyć zamówienia.</p>
      <h3>ZAUTOMATYZOWANE PRZETWARZANIE DANYCH OSOBOWYCH</h3>
      <p>Twoje dane osobowe będą przetwarzane w sposób zautomatyzowany, ale nie będą podlegały profilowaniu.</p>
      <h3>PRAWO DO SKARGI</h3>
      <p>Przysługuje Tobie prawo do wniesienia skargi w związku z tym jak przetwarzane są Twoje dane osobowe do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych z siedzibą w Warszawie (00-193), przy ul. Stawki 2.</p>
      <h2>§ 3<br /> PRZECHOWYWANIE INFORMACJI LUB UZYSKIWANIE DOSTĘPU DO INFORMACJI JUŻ PRZECHOWYWANEJ W TELEKOMUNIKACYJNYM URZĄDZENIU KOŃCOWYM</h2>
      <p>Platforma edukacyjna nie zbiera w sposób automatycznych żadnych informacji, za wyjątkiem informacji zawartych w tzw. ciasteczkach (ang. <em>cookies</em>). Platforma edukacyjna wykorzystuje względem użytkowników tzw. stałe i sesyjne pliki <em>cookies</em>. Ilość plików <em>cookie</em> zapisywanych na urządzeniach poszczególnych użytkowników może być różna — wynika to z rodzaju urządzenia, wykorzystywanej przeglądarki, zainstalowanych wtyczek i ich ustawień. Sesyjne pliki cookies są przechowywane w urządzeniu użytkownika do czasu opuszczenia strony internetowej lub wyłączenia przeglądarki. Stałe pliki cookies są przechowywane w urządzeniu końcowym użytkownika przez określony w ich parametrach czas — może to być czas sesji, czas określony z góry lub czas do ich usunięcia przez użytkownika. Platforma edukacyjna wykorzystuje następujące typy plików cookie:</p>
      <h3>PLIKI NIEZBĘDNE DO ZAPEWNIENIA BEZPIECZEŃSTWA I NIEZAWODNOŚCI</h3>
      <p>Umożliwiają korzystanie z zawartości platformy edukacyjnej w sposób prawidłowy i odpowiadają za podstawowe kwestie związane z bezpieczeństwem Twoich danych - nie możesz wyłączyć tych plików, ale możesz je zablokować. W przypadku blokady niektóre funkcjonalności platformy edukacyjnej nie będą działać w ogóle lub nie będą działać prawidłowo:</p>
      <table width="595">
        <tbody>
          <tr>
            <td width="86">
              <p>dostawca ciasteczka</p>
            </td>
            <td width="113">
              <p>nazwa ciasteczka</p>
            </td>
            <td width="124">
              <p>czas przechowywania</p>
            </td>
            <td width="133">
              <p>co dzieje się dalej z danymi?</p>
            </td>
            <td width="139">
              <p>osoby trzecie uzyskujące dostęp do cookies?</p>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>&nbsp;devstyle.mykajabi.com</p>
            </td>
            <td width="113">
              <p>_kjb_session</p>
            </td>
            <td width="124">
              <p>1 dzień</p>
            </td>
            <td width="133">
              <p>dane pozwalają przechowywać informacje o uprawnieniach zalogowanych użytkowników - w szczególności czy są zwykłymi użytkownikami czy administratorami</p>
            </td>
            <td width="139">
              <p>dane są powierzane Kajabi LLC, nie są udostępniane podmiotom trzecim</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <h3>PLIKI POZWALAJĄCE NA DZIAŁANIA ANALITYCZNE I STATYSTYCZNE</h3>
      <p>Umożliwiają zbieranie informacji o sposobie korzystania przez Ciebie z platformy edukacyjnej, co pomaga nam zrozumieć, w jaki z niej korzystasz. Te pliki pozwalają określić, które materiały wideo są dla Ciebie interesujące. Rezygnacja z tych plików cookie może powodować, że niektóre funkcje strony będą niedostępne:</p>
      <table width="595">
        <tbody>
          <tr>
            <td width="86">
              <p>dostawca ciasteczka</p>
            </td>
            <td width="113">
              <p>nazwa ciasteczka</p>
            </td>
            <td width="124">
              <p>czas przechowywania</p>
            </td>
            <td width="133">
              <p>co dzieje się dalej z danymi?</p>
            </td>
            <td width="139">
              <p>osoby trzecie uzyskujące dostęp do cookies?</p>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>&nbsp;devstyle.mykajabi.com</p>
            </td>
            <td width="113">
              <p>E-v1</p>
            </td>
            <td width="124">
              <p>permanentne</p>
            </td>
            <td width="133">
              <p>dane pozwalają zbierać informacje o tym w jaki sposób użytkownik korzysta z materiałów wideo; te dane są używane w celu zapewnienia, że wideo będzie bardziej przystępne dla oglądających</p>
            </td>
            <td width="139">
              <p>dane są powierzane Kajabi LLC, nie są udostępniane podmiotom trzecim</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <h3>PLIKI UMOŻLIWIAJĄCE DZIAŁANIA MARKETINGOWE</h3>
      <p>Umożliwiają personalizowanie wyświetlanych Tobie reklam, w szczególności w oparciu o dane zebrane w innych serwisach przez tego samego dostawcę danego ciasteczka. Korzystanie z tych plików najczęściej wiąże się z profilowaniem, czy poddawaniem analizie i ocenie lub predykcji zebranych informacji na temat Twojej aktywności, a następnie - w oparciu o te informacje - wyświetlenie reklamy produktu, co do którego zachodzi największe prawdopodobieństwo, że będziesz zainteresowany jego zakupem. Możesz nie wyrazić zgody na stosowanie tych plików cookie. Wówczas nie będziemy personalizować wyświetlanych Tobie reklam, wszystkie funkcje strony będą jednak działać prawidłowo.</p>
      <table width="595">
        <tbody>
          <tr>
            <td width="86">
              <p>dostawca ciasteczka</p>
            </td>
            <td width="113">
              <p>nazwa ciasteczka</p>
            </td>
            <td width="124">
              <p>czas przechowywania</p>
            </td>
            <td width="133">
              <p>co dzieje się dalej z danymi?</p>
            </td>
            <td width="139">
              <p>osoby trzecie uzyskujące dostęp do cookies?</p>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>mykajabi.com</p>
            </td>
            <td width="113">
              <p>_fbp</p>
            </td>
            <td width="124">
              <p>3 miesiące</p>
            </td>
            <td width="133">
              <p>są udostępniane Facebook w celu dostarczania reklam w trybie RTB (aukcyjnym)</p>
            </td>
            <td width="139">
              <p>Kajabi LLC, Facebook Inc.</p>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>Facebook.com</p>
            </td>
            <td width="113">
              <p>fr</p>
            </td>
            <td width="124">
              <p>3 miesiące</p>
            </td>
            <td width="133">
              <p>są udostępniane Facebook w celu dostarczania reklam w trybie RTB (aukcyjnym)</p>
            </td>
            <td width="139">
              <p>Facebook Inc.</p>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>&nbsp;Facebook.com</p>
            </td>
            <td width="113">
              <p>tr</p>
            </td>
            <td width="124">
              <p>sesja</p>
            </td>
            <td width="133">
              <p>są udostępniane Facebook w celu dostarczania reklam w trybie RTB (aukcyjnym)</p>
            </td>
            <td width="139">
              <p>Facebook Inc.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>§ 4<br /> WYJAŚNIENIA DOTYCZĄCE PLIKÓW COOKIES</h2>
      <p><strong>Facebook Inc.</strong> -&nbsp; Facebook dostarcza na platformę szkoleniową tzw. Pixel - kod oprogramowania pozwalający obserwować użytkowników i ich zachowanie na stronach internetowych. Platforma edukacyjna wykorzystuje Facebook Pixel do śledzenia zachowania użytkownika w sposób następujący:</p>
      <p>ustalanie czy użytkownik wyświetlił jakąś zawartość, testował sprzedawaną przez administratora usługę lub faktycznie zakończył rejestrację. Pixel przesyła zebrane informacje dostawcy kodu, spółce Facebook Inc. z siedzibą w Stanach Zjednoczonych Ameryki. Tak zbierane dane mogą być również udostępniane dostawcy platformy szkoleniowej. Na podstawie tych danych Facebook może wyświetlać Tobie reklamy w swoim serwisie oraz w innych miejscach, które podjęły współpracę z Facebookiem w ramach sieci reklamowej. Informacje o Twoich preferencjach są udostępniane w czasie rzeczywistym i następnie poddawane specjalnym aukcjom, podczas których podmioty zainteresowane tym by wyświetlać swoje reklamy osobom o preferencjach takich jak Twoje (lub zbliżone), rywalizują ze sobą w tej sprawie (tzw. dostarczanie reklam w trybie real time buing - czyli akcyjnym).</p>
      <p><strong>Kajabi LLC</strong> - jest dostawcą silnika utrzymującego platformę szkoleniową. Dostarczane przez Kajabi ciasteczka pozwalają poddać analizie dane dotyczące tego, w jaki sposób korzystasz z materiałów wideo zawartych w szkoleniach. Kajabi pełni rolę jedynie procesora danych. Kajabi ma siedzibę w Stanach Zjednoczonych Ameryki, więc tak zbierane dane osobowe wiążą się z koniecznością ich przekazywania poza Europejski Obszar Gospodarczy.</p>
      <p>Ponad to co opisane powyżej, Kajabi dostarcza na platformę szkoleniową ciasteczka, w których zebrane dane Kajabi LLC udostępnia Facebook Inc. To ciasteczko przesyła zebrane informacje dostawcy kodu, spółce Facebook Inc. z siedzibą w Stanach Zjednoczonych Ameryki. Na podstawie tych danych Facebook może wyświetlać Tobie reklamy w swoim serwisie oraz w innych miejscach, które podjęły współpracę z Facebookiem w ramach sieci reklamowej. Informacje o Twoich preferencjach są udostępniane w czasie rzeczywistym i następnie poddawane specjalnym aukcjom, podczas których podmioty zainteresowane tym by wyświetlać swoje reklamy osobom o preferencjach takich jak Twoje (lub zbliżone), rywalizują ze sobą w tej sprawie (tzw. dostarczanie reklam w trybie real time buing - czyli akcyjnym).</p>
      <h2>§ 5<br /> BLOKADA COOKIES</h2>
      <ol>
        <li>Zawsze masz możliwość zablokowania zapisywania plików cookies poprzez odpowiednią zmianę ustawień przeglądarki internetowej.</li>
        <li>Wyłączenie <em>cookies</em> może mieć wpływ na funkcjonowanie platformy edukacyjnej.</li>
        <li>Brak zmiany domyślnych ustawień Twojej przeglądarki oznacza akceptację dla stosowanych <em>cookies</em>. Zgodnie z przepisami polskiego prawa platforma edukacyjna przyjmuje, że możesz wyrazić lub odmówić zgody na wykorzystywanie plików <em>cookies</em> poprzez odpowiednią konfigurację swojej przeglądarki. Zgoda tak wyrażona może być w każdym momencie wycofana.</li>
        <li>Możesz w dowolnym momencie usunąć pliki cookie przechowywane na swoim urządzeniu. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania danych zawartych w plikach cookie do chwili jej wycofania. Wycofanie zgody wyrażonej za pośrednictwem opcji przeglądarki jest szczegółowo opisane na stronach internetowych dostawców poszczególnych przeglądarek — poniżej znajdziesz hiperłącza do tych najpopularniejszych:</li>
        <ol>
          <li>Internet Explorer:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d</a></li>
          <li>Edge: <a href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a></li>
          <li>Mozilla Firefox: <a href="https://support.mozilla.org/pl/kb/ciasteczka">https://support.mozilla.org/pl/kb/ciasteczka</a></li>
          <li>Google Chrome:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">&nbsp;</a><a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647</a></li>
          <li>Opera: <a href="https://help.opera.com/pl/latest/web-preferences/#cookies">https://help.opera.com/pl/latest/web-preferences/#cookies</a></li>
          <li>Safari: <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">https://support.apple.com/pl-pl/guide/safari/sfri11471/mac</a></li>
        </ol>
        <li>Masz możliwość zweryfikowania w każdej chwili swoich ustawień w przeglądarce, również w stosunku do poszczególnych plików cookie. W tym celu możesz skorzystać m.in. z następujących narzędzi:</li>
        <ol>
          <li><a href="http://www.youronlinechoices.com/pl/twojewybory">http://www.youronlinechoices.com/pl/twojewybory</a></li>
          <li><a href="http://optout.aboutads.info/?c=2&amp;lang=EN">http://optout.aboutads.info/?c=2&amp;lang=EN</a></li>
        </ol>
      </ol>
      <p>&nbsp;</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      